<template>
  <nav>
    <router-link to="/">Home</router-link>
    <router-link to="/calc/vps">VPS Calculator</router-link>
  </nav>
  <img alt="Cloudtech logo" class="logo" src="@/assets/logo.png">
  <router-view/>
</template>

<style lang="scss">
html {
  min-height: 100vh;
  font-size: 16px;
  background-color: var(--white-alt);
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: var(--main);
  animation: fade 2s;
}

:root {
  --white: #f9f9f9;
  --white-alt: #e1e1e1;
  --dark: #2c3e50;
  --main: #235071;
  --alt: #42b983;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

nav {
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem 2rem;
  flex-wrap: wrap;
  padding: 2rem;

  a {
    font-weight: bold;
    color: var(--main);

    &.router-link-exact-active {
      color: var(--alt);
    }
  }
}

.logo {
  width: 5rem;
  display: none;
}

/* Animation - fade */
@keyframes fade { 
    from { 
        opacity: .4; 
    } to { 
        opacity: 1; 
    }
}

@media print {
  nav {
    display: none !important;
  }
  .logo {
    display: block !important;
  }
}
</style>