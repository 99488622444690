<template>
  <div class="container">
    <header>
      <div class="header">
        <h1>VPS Calculator</h1>
        <button @click="getData()" class="refreshbtn spinner"><i class="fa-solid fa-arrows-rotate"></i></button>
        <button @click="printPage()" class="refreshbtn"><i class="fa-solid fa-print"></i></button>
      </div>
    </header>
    <div class="loader">
      <span class="bar"></span>
      <span class="bar"></span>
      <span class="bar"></span>
    </div>
    <main class="main-content hidden">
    <div class="main-plan">
      <div class="card">
        <div>Plan:</div>
        <div class="dropdown">
          <button class="card-second-section">{{this.basePlanName}}</button>
            <ul class="dropdown-content">
              <li v-for="item in apiData['vps']" :key="item.id" @click="selectItem(item.id-1)" class="select-plan">
              {{item.name.toUpperCase()}}
              </li>
            </ul>
        </div>
      </div>
      <div class="card">Nucleos:
        <span class="card-second-section" v-if="this.basePlan.vcores">{{this.basePlan.vcores}}</span>
        <span class="card-second-section" v-else>0</span>
        </div>
      <div class="card">RAM:
        <span class="card-second-section" v-if="this.basePlan.vram">{{this.basePlan.vram}} GB</span>
        <span class="card-second-section" v-else>0 GB</span>
      </div>
      <div class="card">Almacenamiento: 
        <span class="card-second-section" v-if="this.basePlan.disk_ssd">SSD {{this.basePlan.disk_ssd}} GB</span>
        <span class="card-second-section" v-else>0 GB</span>
        <span class="card-second-section" v-if="this.basePlan.disk_sas">SAS {{this.basePlan.disk_sas}} GB</span>
      </div>
    </div>
    <div class="main-addons" @click="getAddons()" @keyup="getAddons()" v-if="this.basePlan">
      <label for="vcores" class="card">Nucleos adicionales: <input type="number" id="vcores" class="card-second-section"></label>
      <label for="vram" class="card">RAM adicional: <input type="number" id="vram" class="card-second-section"></label>
      <label for="ip_add" class="card">IP adicional: <input type="number" id="ip_add" class="card-second-section"></label>
      <label for="tsplus" class="card">Licencia TSPLUS: <input type="number" id="tsplus" class="card-second-section"></label>
      <label for="disk_ssd" class="card">Almacenamiento adicional SSD: <input type="number" id="disk_ssd" class="card-second-section"></label>
      <label for="disk_sas" class="card">Almacenamiento adicional SAS: <input type="number" id="disk_sas" class="card-second-section"></label>
      <div class="card checkboxes">
        <div>
          <label for="windows">Windows</label>|<label for="monitoring">Monitoring</label>|<label for="backup">Backup</label>
        </div>
        <div class="card-second-section">
          <input type="checkbox" id="windows"><input type="checkbox" id="monitoring"><input type="checkbox" id="backup">
        </div>
      </div>
    </div>
    <div class="main-value">
      <div class="card">Precio:
        <span class="card-second-section" v-if="this.price">{{this.currency(this.price)}}</span>
        <span class="card-second-section" v-else>0</span>
      </div>
    </div>
    </main>
  </div>
</template>

<script>
import { getAPI } from "../axios-api";

export default {
  name: "Prices",
  data() {
      return {
      urls: ["/cloudtech/api/products/servers/vps/", "/cloudtech/api/products/prices/add/"],
      apiData: {'vps':[],'plus':[]},
      loaded: 0,
      basePlan: '',
      plus: '',
      basePlanName: 'Seleccionar',
      addons: {'vcores': 0, 'vram': 0, 'disk_ssd': 0, 'disk_sas': 0, 'ip_add': 0, 'windows': 0, 'monitoring': 0, 'backup': 0, 'tsplus': 0, 'cpanel': 0},
      price_add: 0,
      price: 0,
      }
  },
  created() {
    this.getData()
  },
  methods: {
    loadingPage() {
      const mainpage = document.querySelector('.main-content')
      const loader = document.querySelector('.loader')
      const refreshbtn = document.querySelector('.refreshbtn')
      mainpage.classList.toggle('hidden')
      loader.classList.toggle('hidden')
      refreshbtn.classList.toggle('spinner')
    },
    currency(number) {
      return new Intl.NumberFormat('es-CO', {style: 'currency',currency: 'COP', minimumFractionDigits: 2}).format(number)
    },
    getData() {
      if (this.loaded) {this.loadingPage(); console.log('loaded')}
      this.urls.forEach(url => {
      getAPI // Get Data from api
        .get(url)
        .then((response) => {
          if (url.includes('vps')) {
            this.apiData['vps'] = response.data
            this.loadingPage()
            this.loaded = 1
          } else {
            this.apiData['plus'] = response.data
          }
        }).catch((err) => { console.error('No se logro obtener nos datos necesarios.'); });
    })},
    selectItem(id) {
      this.basePlan = this.apiData['vps'][id]
      this.basePlanName = this.apiData['vps'][id].name.toUpperCase()
      this.plus = this.apiData['plus'][0]
      const menu = document.querySelectorAll('.select-plan')
      menu.forEach(e => {
        e.classList.remove('active')
        e.addEventListener('click', item =>{
          item.preventDefault()
          item.target.classList.add('active')
        })
      })
      this.updatePrice()
    },
    getAddons() {
      const addint = document.querySelectorAll('.main-addons label input')
      const addbool = document.querySelectorAll('.main-addons .checkboxes div input')
      addint.forEach(e => {
        if (e.value > 0) {
          this.addons[e.id] = parseInt(e.value)
        } else {
          e.value = ''
        }
      })
      addbool.forEach(e => {
        this.addons[e.id] = (e.checked) ? 1 : 0
      })
      this.updatePrice()
    },
    updatePrice() {
      this.price = parseInt(this.basePlan.price_vps[0])
      this.price_add = 0
      for (const prop in this.addons) {
        if (prop === 'backup' && this.addons['backup'] === 1) {
          this.price_add += (this.basePlan.disk_ssd+
          this.basePlan.disk_sas+this.addons.disk_ssd+
          this.addons.disk_sas)*this.plus[prop]
        } else if (prop === 'cpanel') {}
        else this.price_add += (this.addons[prop] * this.plus[prop])
      }
      this.price += this.price_add
    },
    printPage() {
      window.print();
    },
  }
}
</script>

<style lang="scss" scoped>

/* Container */
.container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-bottom: 2rem;
}

.header {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
}
.main-content {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: auto;
  gap: 2rem;
  animation: fade 1s;
}

.main-plan, .main-addons {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 1rem;
}
.card {
  border: 2px solid var(--main);  
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-top: 1rem;
}
.main-plan>.card {
  flex: 1 1 45%;
}

.main-addons>.card {
  flex: 1 1 23%;
  cursor: pointer;
}

.checkboxes>div {
  display: flex;
  justify-content: space-evenly;
  cursor: auto;
}

.checkboxes>div>label {
  flex: 1 1 auto;
  cursor: pointer;
}

input {
  text-align: center;
  flex: 1 1 auto;
}

/* Style The Dropdown Button */
.card-second-section {
  background-color: var(--main);
  color: var(--white);
  width: 100%;
  padding: 1rem 0;
  border-radius: 0 0 .5rem .5rem;
  // outline: 2px solid var(--main);
  font-size: 1.2rem;
  border: none;
}

.dropdown>.card-second-section {
  cursor: pointer;
}

.main-value>.card {
  width: 60%;
  margin: auto;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: var(--white);
  margin-top: -.8rem;
  margin-left: -.12rem;
  width: 100.5%;
  box-shadow: 0 .5rem 1rem 0 rgba(0,0,0,0.2);
  border: 2px solid var(--main);  
  border-top: 0;
  border-radius: 0 0 .5rem .5rem;
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content li {
  color: var(--dark);
  padding: .75rem 1rem;
  text-decoration: none;
  display: block;
  cursor: pointer;
}

.active {
  font-weight: bold;
  background-color: var(--white-alt)
}

/* Change color of dropdown links on hover */
.dropdown-content li:hover {
  background-color: var(--white-alt)
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
  background-color: var(--main);
}

/* Loader open */
.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: .4rem;
  min-height: 50vh;
}
.bar {
  display: inline-block;
  width: .4rem;
  height: 2.8rem;
  background-color: var(--dark);
  border-radius: .8rem;
  animation: scale-up 1.2s infinite linear;
}
.bar:nth-child(2) {
  height: 4.5rem;
  margin: 0 .3rem;
  animation-delay: .3s;
}
.bar:nth-child(3) {
  animation-delay: .6s;
}
/* Loader closed */

/* Utils */ 
.hidden {
  display: none;
}

/* Refresh button */
.refreshbtn {
  font-size: 1.5rem;
  border: 0;
  background: 0;
  color: var(--main);
  cursor: pointer;
}
.spinner {
  animation: spin 4s infinite linear;
}

/* Animation - rotar */
@keyframes spin { 
    from { 
        transform: rotate(0deg); 
    } to { 
        transform: rotate(360deg); 
    }
}
@keyframes scale-up {
  20% {
    background-color: var(--dark);
    transform: scaleY(1.5);
  }
  40% {
    transform: scaleY(1);
  }
}

@media print {
  * {
    border-radius: 0 !important;
    align-content: stretch !important;
  }
  .card {
    align-self: stretch !important;
  }
  .container {
    gap: 3rem;
  }
  .main-content {
    gap: 2rem;
  }
  .refreshbtn {
    display: none;
  }
}

</style>
