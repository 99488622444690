<template>
  <div class="home">
    <img alt="Cloudtech logo" src="../assets/logo.png">
  </div>
</template>

<script>
export default {
}
</script>
